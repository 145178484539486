<template>
  <div class="candidate">
    <InternalHeader
      backBtn="База кандидатов"
      backBtnLink="/candidates"
      backBtnLabel="База кандидатов"
      title="Карточка кандидата"
      class="candidate__header"
    />
    <ModalWindow
      v-if="showAddToVacancyModal"
      @close="showAddToVacancyModal = false"
      class="candidate__modal_vacancy"
    >
      <CandidateAddToVacancy
        @close="showAddToVacancyModal = false"
        @addResumeTo="onAddResumeTo"
        :vacancies="vacanciesList"
      />
    </ModalWindow>
    <ModalWindow
      v-if="showResumeModal"
      @close="showResumeModal = false"
      class="candidate__modal_resume"
    >
      <CandidateResumeModal
        @close="showResumeModal = false"
        @resumeCreated="onResumeCreated"
        :candidate="candidateInfo"
        :vacancy="currentVacancy"
      />
    </ModalWindow>
    <ModalWindow
      v-if="showDublicateModal"
      @close="showDublicateModal = false"
      class="candidate__modal_dublicate"
    >
      <CandidateDublicate
        :data="dublicateData"
        @close="showDublicateModal = false"
        @replaceSuccess="onReplaceSuccess"
        @deleteSuccess="onDublicateDeleteSuccess"
      />
    </ModalWindow>
    <ModalWindow
      v-if="showReplaceModal"
      @close="showReplaceModal = false"
      class="candidate__modal_replace"
    >
      <CandidateReplaceSuccess @close="onReplaceSuccessDone" />
    </ModalWindow>
    <ModalWindow
      class="candidate__response-modal"
      v-if="isShowResponseModal"
      @close="isShowResponseModal = false"
    >
      <CandidateResponseModal @close="isShowResponseModal = false" :id="idJob"/>
    </ModalWindow>
    <ModalWindow
      v-if="showDublicateAttentionModal"
      @close="showDublicateAttentionModal = false"
      class="candidate__attention-modal"
    >
      <CandidateDublicateAttention
        @close="showDublicateAttentionModal = false"
        @replaceSuccess="onReplaceSuccess"
      />
    </ModalWindow>
    <div class="candidate__body">
      <div class="candidate__main">
        <div class="candidate__info candidate-info">
          <div class="candidate-info__header">
            <h2 class="candidate-info__name">{{ candidateInfo.full_name }}</h2>
            <div
              :class="[
                'candidate-info__status',
                {
                  'candidate-info__status_declined': declinedStatus(
                    candidateInfo?.status
                  ),
                },
              ]"
              v-if="candidateInfo?.status"
            >
              {{ candidateInfo.status }}: {{ candidateInfo.status_duration }}
            </div>
          </div>
          <div class="candidate-info__main">
            <div class="candidate-info__field">
              <div class="candidate-info__label">Личные данные:</div>
              <div class="candidate-info__row">
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">Пол:</div>
                  <div class="candidate-info__item-value">
                    {{ genderMap[candidateInfo.gender] }}
                  </div>
                </div>
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">
                    Дата рождения:
                  </div>
                  <div class="candidate-info__item-value">
                    {{ candidateInfo.birthdate }}
                  </div>
                </div>
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">
                    Город проживания:
                  </div>
                  <div
                    class="candidate-info__item-value"
                    v-if="candidateInfo.residence?.name"
                  >
                    {{
                      candidateInfo.residence?.regionDescription
                        ? candidateInfo.residence?.regionDescription + ", "
                        : ""
                    }}{{ candidateInfo.residence?.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="candidate-info__field">
              <div class="candidate-info__label">Контактные данные:</div>
              <div class="candidate-info__row">
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">Телефон:</div>
                  <div class="candidate-info__item-value">
                    {{ candidateInfo.phone }}
                  </div>
                </div>
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">Почта:</div>
                  <div class="candidate-info__item-value">
                    {{ candidateInfo.email }}
                  </div>
                </div>
              </div>
            </div>
            <div class="candidate-info__field">
              <div class="candidate-info__label">Дополнительные данные:</div>
              <div class="candidate-info__row">
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">Опыт работы:</div>
                  <div class="candidate-info__item-value">
                    {{ candidateInfo.work_experience }}
                  </div>
                </div>
                <div class="candidate-info__item">
                  <div class="candidate-info__item-property">Источник:</div>
                  <div class="candidate-info__item-value">
                    {{ candidateInfo.source }}
                  </div>
                </div>
              </div>
            </div>
            <div class="candidate-info__field">
              <div class="candidate-info__label">Должности:</div>
              <div class="candidate-info__tags">
                <div
                  class="candidate-info__tag"
                  v-for="speciality in candidateInfo.specialities"
                  :key="speciality"
                >
                  {{ speciality }}
                </div>
              </div>
            </div>
            <div class="candidate-info__field">
              <div class="candidate-info__label">Метки:</div>
              <div class="candidate-info__tags">
                <div
                  class="candidate-info__tag"
                  v-for="label in candidateInfo.labels"
                  :key="label"
                >
                  {{ label }}
                </div>
              </div>
            </div>
            <div class="candidate-info__field">
              <div class="candidate-info__label">Комментарий:</div>
              <div
                class="candidate-info__commentary"
                v-if="!showMoreComment"
                v-html="candidateInfo.comment.slice(0, 300)"
              ></div>
              <div
                class="candidate-info__commentary"
                v-else
                v-html="candidateInfo.comment"
              ></div>
              <button
                class="candidate-info__button-more"
                v-if="candidateInfo.comment?.length > 240 && !showMoreComment"
                @click="showMoreComment = !showMoreComment"
              >
                Смотреть ещё
              </button>
            </div>
          </div>
        </div>
        <div class="candidate__responses candidate-responses">
          <h3 class="candidate-responses__title">
            Отклики кандидата<span class="candidate-responses__title-count">{{
              candidateInfo.responses
            }}</span>
          </h3>
          <div class="candidate-responses__body">
            <div class="candidate-responses__headers">
              <div class="candidate-responses__head-label block-width_xs">
                Дата
              </div>
              <div class="candidate-responses__head-label block-width_l">
                Вакансия
              </div>
              <div class="candidate-responses__head-label block-width_m">
                Этап
              </div>
              <div class="candidate-responses__head-label block-width_m">
                Причина отказа
              </div>
            </div>
            <div
              class="candidate-responses__responses"
              v-if="candidateResponses?.length"
            >
              <div
                class="candidate-responses__response"
                v-for="(response, index) in candidateResponses"
                :key="index"
                @click="onResponseClick(response)"
              >
                <div class="candidate-responses__response-date block-width_xs">
                  {{ response.date }}
                </div>
                <div class="candidate-responses__response-value block-width_l">
                  {{ response?.vacancy_title }}
                </div>
                <div class="candidate-responses__response-value block-width_m">
                  <span
                  v-if="!response?.is_closed"
                    :class="[
                      'candidate-responses__response-indicator',
                      response?.is_rejected
                        ? 'response-indicator_red'
                        : 'response-indicator_blue',
                    ]"
                  ></span
                  >{{ response?.status_text }}
                </div>
                <div class="candidate-responses__response-value block-width_m">
                  {{ response?.rejection_reason }}
                </div>
              </div>
            </div>
            <div
              v-else-if="!isResponsesLoading"
              class="candidate-responses__responses_empty"
            >
              <p class="candidate-responses__empty-title">
                Список откликов пуст
              </p>
              <p class="candidate-responses__empty-description">
                У кандидата пока нет откликов
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="candidate__aside candidate-aside">
        <div class="candidate-aside__block">
          <div class="candidate-aside__label">Вы можете:</div>
          <div class="candidate-aside__buttons">
            <div class="candidate-aside__button" @click="editCandidate">
              <img
                class="candidate__button-icon"
                src="@/assets/images/icons/pencil.svg"
                alt="pencil"
              />
              Редактировать
            </div>
            <div class="candidate-aside__button" @click="addToVacancy">
              <img
                class="candidate__button-icon"
                src="@/assets/images/icons/plus_blue_bold.svg"
                alt="pencil"
              />
              Добавить к вакансии
            </div>
          </div>
        </div>
        <CandidateDuplicateElement
          v-if="showDublicateElement"
          @checkChanges="openDublicateModal"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import candidates from "@/api/candidates";
import common from "@/api/common";
import InternalHeader from "@/components/InternalHeader";
import CandidateAddToVacancy from "@/components/ModalWindow/ModalBodyes/CandidateAddToVacancy";
import CandidateDublicate from "@/components/ModalWindow/ModalBodyes/CandidateDublicate";
import CandidateResumeModal from "@/components/ModalWindow/ModalBodyes/CandidateResumeModal";
import CandidateReplaceSuccess from "@/components/ModalWindow/ModalBodyes/CandidateReplaceSuccess";
import CandidateResponseModal from "@/components/ModalWindow/ModalBodyes/CandidateResponseModal";
import CandidateDublicateAttention from "@/components/ModalWindow/ModalBodyes/CandidateDublicateAttention";
import CandidateDuplicateElement from "@/components/CandidateDuplicateElement";
const route = useRoute();
const router = useRouter();
const showAddToVacancyModal = ref(false);
const showResumeModal = ref(false);
const showDublicateModal = ref(false);
const showDublicateAttentionModal = ref(false);
const showReplaceModal = ref(false);
const showMoreComment = ref(false);
const showDublicateElement = ref(false);
const isShowResponseModal = ref(false);
const isResponsesLoading = ref(false);
const vacanciesList = ref([]);
const currentVacancy = ref();
const dublicateData = ref();
const candidateInfo = ref({
  id: null,
  full_name: "",
  gender: "",
  birthdate: null,
  age: null,
  residence: {
    id: null,
    name: "",
    type: "",
    description: "",
    regionDescription: "",
    districtDescription: null,
    cityDescription: null,
  },
  phone: "",
  email: null,
  work_experience: null,
  specialities: [],
  source: "",
  status: "",
  labels: [],
  comment: ``,
});
const idJob = ref('')
const candidateResponses = ref([]);

const declinedStatus = computed(() => (statusName) => {
  return (
    statusName?.trim()?.toLowerCase() === "declined" ||
    statusName?.trim()?.toLowerCase() === "отказался" ||
    statusName?.trim()?.toLowerCase() === "отказ"
  );
});
const genderMap = {
  male: "Мужчина",
  female: "Женщина",
};
const addToVacancy = () => {
  showAddToVacancyModal.value = true;
};

const onAddResumeTo = (vacancy) => {
  currentVacancy.value = vacancy;
  showResumeModal.value = true;
};

const openDublicateModal = () => {
  showDublicateModal.value = true;
};

const onReplaceSuccess = () => {
  showDublicateModal.value = false;
  showReplaceModal.value = true;
};

const onDublicateDeleteSuccess = () => {
  fetchCandidate();
  showDublicateModal.value = false;
};
const onReplaceSuccessDone = () => {
  fetchCandidate();
  showReplaceModal.value = false;
};
const onResumeCreated = (id) => {
  idJob.value = id
  isShowResponseModal.value = true;
};
const editCandidate = () => {
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  }).then(() => (showDublicateModal.value = true));
  router.push(`/candidates/${route.params?.id}/edit`);
};

const onResponseClick = (response) => {
  router.push(`/responses/${response?.id}`);
}
const fetchCandidate = () => {
  isResponsesLoading.value = true;
  candidates.getCandidate(route.params?.id).then((res) => {
    if (res?.data) {
      candidateInfo.value = res?.data;
      if (res?.data?.phone) {
        common
          .searchResponsesByPhone(res?.data?.phone)
          .then((res2) => {
            if (res2?.data) {
              candidateResponses.value = res2?.data;
            }
          })
          .finally(() => (isResponsesLoading.value = false));
      }

      if (res?.duplicate) {
        showDublicateElement.value = true;
        dublicateData.value = res?.duplicate;
      } else {
        showDublicateElement.value = false;
      }
    }
  });
};
onMounted(() => {
  fetchCandidate();
  if (!vacanciesList.value.length) {
    common.getCompanyActiveVacancies().then((res) => {
      if (res?.data) {
        vacanciesList.value = res?.data;
      }
    });
  }
});
</script>

<style scoped lang="scss">
.candidate {
  padding-top: 12px;

  &__modal {
    &_vacancy {
      :deep(.modal__body) {
        width: 776px;
      }
    }

    &_resume {
      :deep(.modal__body) {
        width: 832px;
      }
    }

    &_dublicate {
      :deep(.modal__body) {
        width: 832px;
        height: 648px;
        overflow-y: scroll;
        scrollbar-width: thin;
      }
    }
  }
  &__header {
    margin-bottom: 24px;
  }
  &__body {
    display: flex;
    gap: 32px;
  }
  &__main {
    flex: 1 1 auto;
  }
}
.candidate-info {
  margin-bottom: 32px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  &__name {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
  }
  &__status {
    height: 25px;
    padding: 4px 8px;
    border-radius: 6px;
    background-color: #0962a91a;
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    text-align: left;
    &_declined {
      height: 25px;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: #dd46461a;
      color: $red;
      font-weight: 500;
      font-size: 14px;
      line-height: 16.94px;
      text-align: left;
    }
  }
  &__field {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__label {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
  }
  &__row {
    display: flex;
    gap: 44px;
  }
  &__item {
    display: flex;
    gap: 8px;
  }
  &__item-property {
    color: $light-gray-2;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }
  &__item-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }
  &__tags {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  &__tag {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    border: 1px solid $light-gray-2;
    border-radius: 4px;
    white-space: nowrap;
  }
  &__button-more {
    margin-top: 8px;
    background-color: transparent;
    outline: none;
    border: none;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
  }
  &__commentary {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    :deep(li) {
      margin-left: 14px;
    }
  }
}

.candidate-responses {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    min-height: 372px;
  }
  &__title {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
  }
  &__title-count {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    color: $gray;
  }
  &__headers {
    display: flex;
    align-items: center;
    gap: 44px;
    max-width: 832px;
    margin-bottom: 12px;
    height: 40px;
    padding: 0 24px;
    background-color: #fff;
    border-radius: 12px;
  }
  &__head-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: $dark-blue;

    &_xs {
      width: 80px;
    }
    &_m {
      width: 156px;
    }
    &_l {
      width: 260px;
    }
  }
  &__responses {
    flex: 1 1 auto;
    height: 100%;
    &_empty {
      margin-top: 156px;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  &__response {
    display: flex;
    align-items: center;
    gap: 44px;
    max-width: 832px;
    margin-bottom: 12px;
    min-height: 66px;
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 16px;
    cursor: pointer;
  }
  &__response-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: $gray;
  }
  &__response-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
  }
  &__response-indicator {
    display: inline-block;
    margin-right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &__empty-title {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: center;
  }
  &__empty-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
    color: $gray;
  }
}
.candidate-aside {
  &__block {
    padding: 24px;
    width: 256px;
    background-color: #fff;
    border-radius: 16px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__label {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
  }
  &__label-box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
  &__label-text {
    max-width: 140px;
    height: 34px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__button {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 32px;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    cursor: pointer;
  }
  &__footer {
    border-top: 1px solid $light-gray-1;
  }
  &__footer-title {
    margin-top: 16px;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    cursor: pointer;
  }
}

.block-width {
  &_xs {
    width: 80px;
  }
  &_m {
    width: 156px;
  }
  &_l {
    width: 260px;
  }
}

.response-indicator {
  &_blue {
    background-color: $blue;
  }
  &_red {
    position: relative;
    background-color: $red;
    &::after {
      position: absolute;
      width: 6px;
      height: 6px;
      top: 20%;
      left: 20%;
      content: "";
      background-color: #fff;
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
      z-index: +1;
      border-radius: 20px;
    }
  }
}
</style>
