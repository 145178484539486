<template>
  <div class="candidate-resume">
    <div class="candidate-resume__header">
      <h2 class="candidate-resume__title">Резюме</h2>
      <div class="candidate-resume__close" @click.prevent="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5L15 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="candidate-resume__body">
      <div class="candidate-resume__form">
        <section class="candidate-resume__form-section">
          <h3 class="candidate-resume__form-title">Данные кандидата</h3>
          <div class="candidate-resume__form-row">
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input"
                placeholder="ФИО*"
                :error="v$.full_name.$error"
                v-model="form.full_name"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$.full_name.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input"
                placeholder="Дата рождения*"
                :error="v$.birthdate.$error"
                v-model="form.birthdate"
                v-mask="'00.00.0000'"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$.birthdate.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
          </div>
          <div class="candidate-resume__form-row_wrap">
            <div class="candidate-resume__form-field">
              <VSelect
                class="candidate-resume__input"
                placeholder="Пол*"
                :error="v$.gender.$error"
                :options="genderOptions"
                v-model="form.gender"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$.gender.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input candidate-resume__input_disabled"
                placeholder="Телефон*"
                v-model="form.phone"
                :error="v$.phone.$error"
                v-mask="'+0(000)-000-00-00'"
                type="text"
                name="mask"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$.phone.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
            <div class="candidate-resume__form-field">
              <VSearchSelect
                class="candidate-resume__input"
                placeholder="Город проживания*"
                :error="v$.residence.$error"
                :items="resultsResidence"
                @selectItem="onSelectResidence"
                @input="debouncedSearchResidence"
                v-model="form.residence.name"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$.residence.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
          </div>
        </section>

        <section
          class="candidate-resume__form-section"
          v-for="(exp, index) in form.experience"
          :key="index"
        >
          <div class="candidate-resume__form-row_justify">
            <h3 class="candidate-resume__form-subtitle">Опыт работы</h3>
            <div
              class="candidate-resume__form-button-wrapper"
              @click="deleteExperience(index)"
            >
              <img
                class="candidate-resume__form-button-icon"
                src="@/assets/images/icons/trash-red.svg"
                alt="plus"
              />
              <button class="candidate-resume__form-button_delete">
                Удалить опыт работы &nbsp;
              </button>
            </div>
          </div>

          <div class="candidate-resume__form-row">
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input"
                placeholder="Название организации*"
                v-model="form.experience[index].company"
                :error="v$?.experience?.[index]?.company?.$error"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$?.experience?.[index]?.company?.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input"
                placeholder="Специальность*"
                :error="v$?.experience?.[index].specialty?.$error"
                v-model="form.experience[index].specialty"
              />
              <p
                class="candidate-resume__form-field-error"
                v-if="v$?.experience?.[index].specialty?.$error"
              >
                Заполните обязательное поле
              </p>
            </div>
          </div>
          <div class="candidate-resume__form-row">
            <div class="candidate-resume__form-field">
              <VCustomInput
                class="candidate-resume__input"
                placeholder="Трудовые функции"
                v-model="form.experience[index].function"
              />
            </div>
            <div class="candidate-resume__form-row_dates">
              <div class="candidate-resume__form-field_date">
                <VCustomInput
                  class="candidate-resume__input_date"
                  placeholder="Начало работы*"
                  v-model="form.experience[index].begin_date"
                  v-mask="'00.00.0000'"
                  :error="v$?.experience?.[index].begin_date?.$error"
                />
                <p
                  class="candidate-resume__form-field-error"
                  v-if="v$?.experience?.[index].begin_date?.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>
              <div
                class="candidate-resume__form-field_date"
                v-if="!form.experience[index]?.isStillWork"
              >
                <VCustomInput
                  class="candidate-resume__input_date"
                  placeholder="Окончание работы*"
                  v-model="form.experience[index].end_date"
                  v-mask="'00.00.0000'"
                  :error="v$?.experience?.[index].end_date?.$error"
                />
                <p
                  class="candidate-resume__form-field-error"
                  v-if="v$?.experience?.[index].end_date?.$error"
                >
                  Заполните обязательное поле
                </p>
              </div>
            </div>
          </div>
          <div class="candidate-resume__form-row">
            <p>Работаю до сих пор</p>
            <VCheckbox
              v-model="form.experience[index].isStillWork"
              @click="experienceSwitchClick(index)"
            />
          </div>
        </section>
        <div
          class="candidate-resume__form-button-wrapper"
          @click="addExperience"
        >
          <img
            class="candidate-resume__form-button-icon"
            src="@/assets/images/icons/plus-blue.svg"
            alt="plus"
          />
          <button class="candidate-resume__form-button">
            Добавить опыт работы &nbsp;
          </button>
        </div>
      </div>
      <div class="candidate-resume__btns">
        <VButton
          label="Назад"
          bg="#E4E7EE"
          bgHover="#BBC4CD"
          color="#1E3959"
          colorHover="#1E3959"
          class="candidate-resume__btn"
          @click="$emit('close')"
        />

        <VButton
          label="Создать отклик"
          bg="#0086B2"
          color="#fff"
          class="candidate-resume__btn"
          @click="createResponse"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import responses from "@/api/responses";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "@/api/common";
import { debounce } from "@/services/helpers";
const emit = defineEmits(["close", "resumeCreated"]);
const props = defineProps({
  vacancy: {
    type: Object,
    required: true,
  },
  candidate: {
    type: Object,
    required: true,
  },
});
const genderOptions = [
  {
    id: "male",
    label: "Мужчина",
  },
  {
    id: "female",
    label: "Женщина",
  },
];

const form = reactive({
  vacancy_id: null,
  full_name: "",
  gender: "male",
  birthdate: "",
  phone: "",
  residence: {
    name: "",
  },
  residence_id: null,
  experience: [],
});
const resultsResidence = ref([]);
const rules = computed(() => {
  const rules = {
    full_name: { required },
    birthdate: { required },
    residence: { required },
    phone: { required },
    gender: { required },
    experience: {},
  };

  if (form.experience?.length) {
    form.experience.forEach((exp, index) => {
      rules.experience[index] = {
        company: { required },
        specialty: { required },
        begin_date: { required },
        ...(!form.experience?.[index]?.isStillWork && {
          end_date: { required },
        }),
      };
    });
  }

  return rules;
});

const v$ = useVuelidate(rules, form);

const addExperience = () => {
  const experience = {
    company: "",
    specialty: "",
    function: "",
    begin_date: "",
    end_date: "",
    isStillWork: false,
  };
  form.experience.push(experience);
};

const deleteExperience = (index) => {
  form.experience = form.experience.filter((_, idx) => idx !== index);
};
const experienceSwitchClick = (index) => {
  if (form.experience?.[index]?.isStillWork) {
    form.experience[index].end_date = null;
  } else {
    form.experience[index].end_date = null;
  }
};

const searchResidence = async () => {
  const res = await common.searchResidence(form.residence.name);
  if (res?.data)
    resultsResidence.value = res?.data.map((item) => {
      return {
        ...item,
        name: item?.fullDescription? item?.fullDescription: item?.name,
      };
    });
};

const onSelectResidence = (item) => {
  form.residence_id = item?.id;
  form.residence.name = item?.fullDescription? item?.fullDescription: item?.name;
};

const debouncedSearchResidence = debounce(() => searchResidence(), 400);

const createResponse = async () => {
  const validate = await v$.value.$validate();
  if (!validate) {
    return;
  }

  responses.postMakeResponse(form).then((res) => {
    if (res) {
      emit("resumeCreated", res.id);
      emit("close");
    }
  });
};

onMounted(() => {
  form.vacancy_id = props?.vacancy?.id;
  form.full_name = props.candidate?.full_name;
  form.gender = props.candidate?.gender;
  form.birthdate = props.candidate?.birthdate;
  form.phone = props.candidate?.phone;
  form.residence_id = props.candidate?.residence?.id;
  form.residence.name = props.candidate?.residence?.regionDescription
    ? `${
        props.candidate?.residence?.regionDescription
          ? props.candidate?.residence?.regionDescription + ", "
          : ""
      }${props.candidate?.residence?.name}`
    : `${props.candidate?.residence?.name}`;
});
</script>
<style lang="scss" scoped>
.candidate-resume {
  position: relative;
  display: flex;
  flex-direction: column;

  &__response-modal {
    :deep(.modal__body) {
      width: 352px !important;
    }
  }

  :deep(.v-filtration) {
    border-radius: 4px;
    background-color: $light;
  }
  :deep(.v-filtration__filter) {
    width: 186px;
    border-radius: 4px;
  }
  :deep(.v-filtration__filter_active) {
    .v-filtration__label {
      font-weight: 400;
    }
  }

  &__header {
    border-bottom: 1px solid $light-gray-1;
    padding: 13px 24px 12px;
  }
  &__body {
    padding: 24px;
    max-height: 381px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
  }

  &__search-block {
    margin-bottom: 24px;
  }

  &__search-input {
    width: 448px;
    border: 1px solid $light-gray-1;

    &::v-deep(.v-input__input) {
      &::placeholder {
        color: $dark-blue;
        opacity: 1;
      }
    }
  }
  &__input {
    &_half {
      max-width: calc(25%);
    }
    &_date {
      flex: 1 1 auto;
    }

    &_disabled {
      pointer-events: none;
      user-select: none;
      color: $dark-blue;
      :deep(.v-input__input) {
        color: $dark-blue;
      }
    }
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__form-section {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__form-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  &__form-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }

  &__form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    &_wrap {
      display: flex;
      gap: 16px;
      margin-bottom: 16px;
      flex-wrap: wrap;
    }
    &_justify {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    &_dates {
      display: flex;
      gap: 16px;
      max-width: calc(50% - 8px);
      flex: 1 1 auto;
    }
  }
  &__form-button-wrapper {
    display: flex;
    align-items: center;
    height: 32px;
  }
  &__form-button {
    color: $blue;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: right;
    &_delete {
      color: $red;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: right;
    }
  }
  &__form-button-icon {
    margin-right: 4px;
  }

  &__form-field {
    width: 100%;
    max-width: calc(50% - 8px);
    :deep(.v-input) {
      width: 100%;
    }
    &_date {
      width: 100%;
      :deep(.v-input) {
        width: 100%;
      }
    }
  }

  &__form-field-error {
    margin-top: 4px;
    font-size: 14px;
    color: $red;
  }
  &__input {
    flex: 0 1 calc(50% - 8px);
  }
  &__switch {
    flex: 0 1 calc(50% - 8px);
  }
  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:first-child {
      width: calc(50% - 8px);

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      width: calc(50% - 8px);

      &:active {
        background-color: #006894 !important;
      }
    }
  }

  :deep(.v-checkbox) {
    flex: 0 0 auto;
    margin-left: auto;
  }
}
</style>
